
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import PolicySummary from "@/components/reusable/policy-information/PolicySummary.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "underwriter-motor-insurance-policy-information",
  components: { PolicySummary },
  props: ["publicId"],
  data() {
    return {
      policyDetails: [],
      policySummary: {
        reference: "",
        publicId: "",
        invoiceData: { totalPremium: "" },
      },
      creditNotes: [],
    };
  },
  setup() {
    const { reinitialization, useReusableNavigation } = ResusableFunctions();
    const tabIndex = ref(0);

    onMounted(() => {
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Motor Insurance", [
        "Client",
        "Polcies",
        "Policy Information",
      ]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event: any) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      setActiveTab,
      reinitialization,
      useReusableNavigation,
    };
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get the details of the vehicle in schdule list
    this.getVehicleDetails();

    //Set page title
    document.title =
      "Underwriter: Motor Insurance Policy Details | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getVehicleDetails() {
      const router = useRouter();
      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.UNDERWRITERS_POLICY_DETAILS_ROUTE}/${this.publicId}/details`
        )
          .then(({ data }) => {
            this.policyDetails = data.data.items;
            this.creditNotes = data.data.creditNotes;
            this.policySummary = data.data.policySummary;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
